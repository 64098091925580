
var services = {
    init: function () {
        $('.carousel').carousel();

        $('.menu').click(function(){
            if($(this).closest('.sidenav').hasClass('aberto')){
                $(this).closest('.sidenav').removeClass('aberto')
            }else{
                $(this).closest('.sidenav').addClass('aberto')
            }
        });

        $('.sidenav ul li a').click(function(){
            var altura = $('#'+$(this).data('section')).offset().top;
            $("html, body").animate({
                scrollTop:altura
            },500);
            $('.menu').trigger("click")
        })


    },
    slide : function () {
        // console.log('a')
        // $('#slides').cycle({ 
        //     speed:  'fast', 
        //     timeout: 6000, 
        //     pager:  '#pager',
        //     containerResize: true,
        //     slideResize:   false
        //     // width: 1
        // });

        $('#slides').bxSlider({
            // mode:'fade',
            controls:false,
            adaptiveHeight:true,
            responsive:true,
            oneToOneTouch:true,
            touchEnabled:true,
            swipeThreshold:1
        })
    }
}

/** Scripts **/
$(function(){

    services.init();

    services.slide();

});
